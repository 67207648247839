import React from 'react'
import {
    Link
  } from "react-router-dom";
  import {Helmet} from "react-helmet";
  import Faq_img from '../assets/img/faq-img.webp';
  import Faq_img_png from '../assets/img/faq-img.png';

  

const Terms = () => {
  return (
    <>
    <Helmet>
  <title>Visuals Clipping Terms &amp; Conditions| Visuals Clipping</title>
  <meta name="description" content="Go to the website to learn more about Visuals Clipping Terms &amp; Conditions. It is helpful for your when you have our service. Visit us and read out loud." />
</Helmet>
      <section className='c-inner-banner'>
        <div className='container'>
            <div className='row align-items-center'>
                <div className='col-lg-9 col-md-8'>
                    <div className='c-inner-banner-con'>
                        <h3>Terms & Conditions</h3>
                        <p>These Additional Terms & Conditions ('T&C') apply to all agreements pursuant to which Visuals Clipping will provide its Services.</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-4'>
                  <div className='c-inner-banner-img'>
                      <picture>
                            <source srcset={Faq_img} type="image/webp" alt="visuals clipping " />
                            <source srcset={Faq_img_png} type="image/png" alt="visuals clipping "/>
                            <img src={Faq_img_png} alt="yvisuals clipping"  />
                        </picture>
                  </div>
                </div>
            </div>
        </div>
    </section>
    <section className='c-about-w c-faq-group-main c-privacy-w'>
        <div className='container'>
            <div className='c-about-hw c-faq-group-in'>
                  <div className='row'>
                  <div className="col-md-12">
                    <div className="contact-form">
                   
                    <h2 className="main-sub-heading mb-4">Service</h2>
                    <p>Visuals Clipping will process the Visual Content within the timeframe as described in the Client Agreement. The Client accepts that additions or amendments demanded to the agreed Service by the Client or modifications of the the forecast may influence the Turnaround Time and the reciprocal responsibilities of Visuals Clipping and the Client.</p>
                    <p>The Visual Content as provided by Visuals Clipping shall be deemed to be in conformity with the Agreement if it substantially meets the specifications described in the Agreement. If it does not substantially meet the applicable specifications, the Client is, as an exclusive remedy, entitled to request Visuals Clipping to re-execute the relevant Service at no additional costs by rejecting the relevant Visual Content, using the review tool in the online application.</p>
                    <p>The Client's right to request Visuals Clipping to re-execute the Service will lapse after 15 days following notification by Visuals Clipping of delivery of the Visual Content. Upon the lapse of the 15 days period, the Visual Content will be deemed to have been accepted and to be in conformity with the Agreement.</p>
                    <br></br>
                    <h2 className="main-sub-heading mb-4">The Service Network</h2>
                    <p>The Client acknowledges and agrees that Visuals Clipping will be permitted to engage its Service Network for the provision of the Services. The Service Network Visuals Clipping has established consists of a number of carefully selected professional partners with which Visuals Clipping has agreements in place. The Visuals Clipping Service Network includes both the Visuals Clipping production facilities and software providers engaged to provide the Services. Everything Visuals Clipping commits itself to pursuant this Agreement, also applies to the Visuals Clipping Service Network. Visuals Clipping regards their services as part of its own responsibility towards clients.</p>
                    <br></br>
                    <h2 className="main-sub-heading mb-4">IP Rights &amp; Data</h2>
                    <p>Visuals Clipping grants the Client an unlimited, personal, exclusive license to use the Visual Content as created by Visuals Clipping. All pre-existing Intellectual Property of each party will remain the exclusive property of that party and, except as specifically provided in this Agreement, no party will acquire any rights or interests in the other party`'s pre-existing Intellectual Property.</p>
                    <p>Visuals Clipping may store and use Visual Content inputs (e.g. image and video inputs) processed by the Service to provide and maintain the Service and to improve and develop the quality of its editing automation and to train its designers. Visuals Clipping shall not store or use any personally identifiable information such as contact details (name and email address) that may be contained in the Client’s content for these purposes.</p>
                    <br></br>
                    <h2 className="main-sub-heading mb-4">Price and Payment</h2>
                    <p>If payment (in full) has not yet been received by Visuals Clipping on due date, Visuals Clipping will first contact the Client either by email or by phone. After that the Client will be in default without prior demand or notice of default being required.</p>
                    <p>If the Client, despite demand or notice of default, still fails to pay the amounts due, Visuals Clipping may hand over the claim for collection. In that event all costs incurred by Visuals Clipping, in connection with overdue payments will be on the Client’s account. The extrajudicial costs are fixed at no less than 15% of the invoiced amount subject to a minimum of INR 15000, 00 excluding TAX since Visuals Clipping insurance company requires to demand that.</p>
                    <p>Complaints in relation to invoices and/or our Services will not suspend the Client’s payment obligations.</p>
                    <br></br>
                    <h2 className="main-sub-heading mb-4">Privacy</h2>
                    <p>Visuals Clipping respects the Client’s privacy and the Visuals Clipping Privacy Policy informs the Client of the actual policy regarding the collection, use and disclosure of personal information Visuals Clipping receives when the Client makes use of the Services. By using the Services, the Client agrees to the collection and use of information in accordance with the Visuals Clipping Privacy Policy.</p>
                    <p>Visuals Clipping will use the collected Personal Data to provide the Service and to manage Client’s account and to enable invoicing. Visuals Clipping may use the collected data for marketing communication purposes as well. Subject to the provisions regarding ‘The Service Network’ in these T&amp;C, Visuals Clipping will not share Personal Data with third parties without Client’s prior consent except where Visuals Clipping is required to do so by law. If Visuals Clipping processes Personal Data on Client’s behalf, Visuals Clipping complies with all obligations under the India General Data Protection Regulation.</p>
                    <br></br>
                    <h2 className="main-sub-heading mb-4">Guarantees and Indemnities</h2>
                    <p>Visuals Clipping depends on the Visual Content provided by the Client. In that regard Client guarantees the following:</p>
                    <p>Client is fully entitled to upload the Visual Content and has obtained any necessary consents with respect to any individual’s data protection and the protection of privacy.</p>
                    <p>the Visual Content is correct, complete, unencumbered and not limited or restricted by, and does not infringe upon, any third parties’ rights, including IP Rights, and the use of the images by Visuals Clipping is not in any other way unlawful in respect of third parties; Client will never submit Visual Content that is in any way discriminating, offensive, obscene, violent, and unlawful or harming the interests and reputation of Visuals Clipping.</p>
                    <p>To ensure that all clients can make use of the Services, the Client guarantees fair use of the Services. Hence, the Client will not use the Services unreasonable and will not intentionally use or enable or demand any third party to use or enable viruses, Trojan horses, worms, bots, or other software which may cause damage to the Services, the website, or any other technical aid which may render them inaccessible or which are intended to circumvent technical protective measures. Visuals Clipping reserves the right to block access for unauthorized use of the system. The Client shall indemnify Visuals Clipping and holds Visuals Clipping harmless from and against any and all costs (including reasonable legal fees) and damages incurred by Visuals Clipping as a result of.</p>
                    <p>any third party claim which is made against Visuals Clipping arising out of or in any way connected with an alleged breach by the Client of its obligations under the Agreement, including these T&amp;C; or The Client’s other unauthorized use of the Service.</p>
                    <br></br>
                    <h2 className="main-sub-heading mb-4">Limitation of liability</h2>
                    <p>Except for indemnified claims that are fully covered by the other party`'s insurance policies in place, the total liability of each party towards the other for breach of contract, in tort (including negligence) or otherwise, in any calendar year shall be limited to compensation of the direct damage the amount of which shall not exceed the total aggregate amount of the Service Fees paid or payable by the Client to Visuals Clipping during the calendar year immediately preceding the year in which the damage occurred.</p>
                    <p>Visuals Clipping must be informed concerning any claim to damage on the shortest notice after the occurrence of the damage. Any claim for damages shall lapse by the mere expiration of 4 weeks after the claimed damage occurred.</p>
                    <p>Parties can only be held liable for direct damage and neither party shall be liable for any indirect or consequential damages, or loss of exploitation, productivity, reputation, profits, contracts, investments, time, data, files, programs and/or documentation.</p>
                    <p>Neither party limits or excludes its liability for wilful misconduct, gross negligence, fraud, fraudulent misrepresentation, death or personal injury.</p>
                    <br></br>
                    <h2 className="main-sub-heading mb-4">Force Majeure</h2>
                    <p>Visuals Clipping shall not be liable for any delay or failure to perform its obligations under the Agreement if that delay or failure arises directly or indirectly through force majeure, which is deemed to include an act of God or (without limitation), war, terrorism or other civil disturbance, decisions of any civil authority, fires, flood, strikes or any other circumstances that affect the operations and that can reasonably be deemed beyond the control of Visuals Clipping including, without limitation, the failure of internet services.</p>
                    <p>If Visuals Clipping cannot perform its obligations under the Agreement as a result of force majeure for a period of more than six(6) weeks, the Client shall have the right to terminate the Agreement with immediate effect without Visuals Clipping limiting its other rights or remedies or incurring any liability towards the Client in respect of such termination.</p>
                    <br></br>
                    <h2 className="main-sub-heading mb-4">Confidentiality</h2>
                    <p>Parties shall maintain all information they receive from or about each other strictly confidential, including but not limited to information concerning Visual Content and the Services. Information will in any case be regarded to be confidential, if one of the Parties indicates in writing that it is confidential.</p>
                    <p>Parties will impose this obligation of confidentiality also on their employees and third parties hired for the performance of their obligations here under; in particular Visuals Clipping shall impose this confidentiality obligation on the members of its Service Network. A party shall not be required to maintain confidentiality with respect to any information that i) was already in the possession of such party on a non-confidential basis ii) has lawfully come into the possession of such a party on a non-confidential basis or iii) is legally required to be disclosed to a judicial or administrative authority.</p>
                    <br></br>
                    <h2 className="main-sub-heading mb-4">Term and termination</h2>
                    <p>The Agreement becomes effective once the parties have signed it and shall be in effect for the term as agreed by the Parties or for as long as Visuals Clipping performs Services for the Client. Except as set out in these T&amp;C, the Client is not entitled to terminate the Agreement early. Either party may terminate the Agreement with immediate effect upon written notice to the other party, if:</p>
                    <p>the other party applies for a moratorium of payments, is declared bankrupt or otherwise will no longer be able to meet its financial obligations under the Agreement;</p>
                    <p>The other party materially breaches the Agreement and, if such breach can be remedied, fails within a reasonable period, after having been given notice of such breach in writing by the non-breaching party to remedy such breach.</p>
                    <p>If the Client acts in a manner reasonably adjudged by Visuals Clipping to be detrimental, in particular when the Client does not fulfil its obligations and guarantees under the Agreement, Visuals Clipping is entitled to suspend provision of the Service. Visuals Clipping shall not be liable for any loss arising in respect of the foregoing.</p>
                    <br></br>
                    <h2 className="main-sub-heading mb-4">Governing Law</h2>
                    <p>The Agreement and any use the Client makes of the Service are subject to Delhi India law, excluding its conflict of laws principles. Any dispute relating in any way to the Client’s visit to the Website and the Service, shall be submitted to arbitration in Delhi City, except that, to the extent the Client has in any manner violated or threatened to violate Visuals Clipping Intellectual Property Rights, Visuals Clipping may seek injunctive or other appropriate relief in a state court in Delhi City, and the Client consents to exclusive jurisdiction and venue in such court. Arbitration shall be conducted under the Expedited Procedures and Rules of the American Arbitration Association. The arbitrator’s award shall be binding and may be entered as a judgment in any court of competent jurisdiction.</p>
                    <br></br>
                    <h2 className="main-sub-heading mb-4">General provisions</h2>
                    <p><strong>Survival:</strong></p>
                    <p>the provisions on IP Rights, Privacy and Confidentiality shall survive termination of the Agreement for whatever reason, and, in addition, the obligations of the Parties under the Agreement that by their nature continue beyond the expiration of the Agreement, shall survive any termination or cancellation of the Agreement.</p>
                    <p><strong>Language:</strong></p>
                    <p>the Agreement is concluded in the English language, which language shall be controlling in all respects.</p>
                    <p><strong>Invalid or unenforceable clauses:</strong></p>
                    <p>If any part of the Agreement including these T&amp;C is found invalid or unenforceable, that part will be enforced to the maximum extent permitted by law and the remainder of the Agreement will remain in full force.</p>
                    <br></br>
                    <h2 className="main-sub-heading mb-4">Definitions</h2>
                    <h2 className="main-sub-heading mb-4">The following terms shall have the meaning set forth below:</h2>
                    <p>Additional Terms &amp; Conditions ('T&amp;C'):</p>
                    <p>The present part of the Agreement describing the additional terms &amp; conditions</p>
                    <hr></hr>
                    <h2 className="main-sub-heading mb-4">The present part of the Agreement describing the additional terms &amp; conditions for Enterprise clients, other than the Client Agreement;</h2>
                    <p>Agreement:</p>
                    <p>The entire agreement, including these Terms and Conditions, concluded between Visuals Clipping and the Client for provision of the Service.</p>
                    <hr></hr>
                    <p>Visuals Clipping:</p>
                    <p>A company with its principal place of business at I-45 Lajpat Nagar Part 1 Delhi 110024 India</p>
                    <hr></hr>
                    <p>Client:</p>
                    <p>Any individual or business that has entered into an Agreement with Visuals Clipping.</p>
                    <hr></hr>
                    <p>Client Agreement:</p>
                    <p>The part of the Agreement describing the specific agreements with the Client, other than the Additional Terms &amp; Conditions;</p>
                    <hr></hr>
                    <p>Guaranteed Services Value:</p>
                    <p>The Client's guarantee to reach the forecasted Services Value each Service Period as of the Service Start Date.</p>
                    <hr></hr>
                    <p>IP Rights:</p>
                    <p>All intellectual property and ancillary rights, such as copyrights, trademark rights, patent rights, design rights, trade name rights, as well as know-how rights;</p>
                    <hr></hr>
                    <p>Parties:</p>
                    <p>Visuals Clipping and Client, each being party.</p>
                    <hr></hr>
                    <p>Personal Data:</p>
                    <p>Any information relating to an identified or identifiable natural person. More specific in this Agreement it is limited to the contact details (name and email address) of natural persons working for Client to the extent necessary in the fulfilment of this Agreement.</p>
                    <hr></hr>
                    <p>Service:</p>
                    <p>The services provided by Visuals Clipping to Client under the terms of this Agreement;</p>
                    <hr></hr>
                    <p>Service Fee:</p>
                    <p>The charges, fees or price for the Service;</p>
                    <hr></hr>
                    <p>Service Start Date:</p>
                    <p>The service period that starts immediately after the onboarding period(if any).</p>
                    <hr></hr>
                    <p>Service Network:</p>
                    <p>The service network Visuals Clipping has established, apart from its own corporate group, and which consists of a number of carefully selected professional companies, with which Visuals Clipping has agreements in place and which are engaged in the provision of the Visuals Clipping Service;</p>
                    <hr></hr>
                    <p>Turnaround Time:</p>
                    <p>The amount of time taken to complete the Services starting at the moment Visuals Clipping has received the Visual Content from the Client properly in its systems until the return of the complete output to the Client.</p>
                    <hr></hr>
                    <p>Visual Content:</p>
                    <p>All visual content (e.g. image, video) submitted by the Client to be processed by Visuals Clipping.</p>
                </div>
                </div>
                </div>  
            </div>
        </div>
    </section>
    </>
  )
}

export default Terms
