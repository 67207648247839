import React from 'react'
import {
  Link
} from "react-router-dom";
import { HiMiniUserGroup } from "react-icons/hi2";
import About_banner from '../assets/img/about-page-banner.png';
import About_banner_2 from '../assets/img/about-page-banner.webp';
import {Helmet} from "react-helmet";

import About_Feature_1 from '../assets/img/about-featre1.webp';
import About_Feature_2 from '../assets/img/about-featre2.webp';
import About_Feature_3 from '../assets/img/about-featre3.webp';

import About_Feature_png_1 from '../assets/img/about-featre1.png';
import About_Feature_png_2 from '../assets/img/about-featre2.png';
import About_Feature_png_3 from '../assets/img/about-featre3.png';


import Satisfied from '../assets/img/100-satisfied.webp';
import Marquee from "react-fast-marquee";

import Company_1 from '../assets/img/px-conversions/company-1.webp';
import Company_2 from '../assets/img/px-conversions/company-2.webp';
import Company_3 from '../assets/img/px-conversions/company-3.webp';
import Company_4 from '../assets/img/px-conversions/company-4.webp';
import Company_5 from '../assets/img/px-conversions/company-5.webp';
import Company_6 from '../assets/img/px-conversions/company-6.webp';
import Company_7 from '../assets/img/px-conversions/company-7.webp';

import Vision from '../assets/img/vision.webp';
import Vision_png from '../assets/img/vision.webp';

import { LazyLoadImage } from 'react-lazy-load-image-component';






const About = () => {
  return (
    <>
    
        <Helmet>
                <meta charSet="utf-8" />
                <title>About-US |Our Vision| Our Mission | Visuals Clipping</title>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="description" content="Know more about About us so you can understand Our Vision and Our Mission at Visuals Clipping. As a result, you can get familiar with our service and build trust between us."/>
            </Helmet>
        <section className="c-banner-w">
	<div className="container">
		<div className="row align-items-center">
			<div className="col-lg-6 col-md-12">
				<div className="c-banner-con">
					<h5>
            <span>
          <HiMiniUserGroup  />
          </span>
                    About Us
					</h5>
					<h1 className='mb-3'>We Have More  <span>20+ Years</span>  Of Photo Editing Industries</h1>

          <div className='c-about-banner-con mb-3'>
                <p>We are Visuals Clipping. We are a decade-old company that has been providing premium-quality image editing services to global clients for over a decade now. We have a team of highly skilled and experienced editors who can handle all kinds of image editing assignments with ease.</p>
                <p>Our services include clipping path, image retouching, image manipulation, photo restoration, photo enhancement, and much more. Our photo retouching and editing solutions are available to clients belonging to the USA, UK, France, Switzerland, Belgium, South Africa, Australia, Canada, and Norway.</p>
          </div>
					
					<div className="c-banner-btn">
						<Link  to="/contact" className="c-btn-1">
							  <span>	Get Quote Now </span>
						</Link>
						
					</div>
				</div>
			</div>
			<div className="col-lg-6 col-md-12">
				<div className="c-banner-slider">
					<div className="c-banner-slider-list-w">
          <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={489}
                                            src={About_banner_2} 
                                            width={679}  
                                            effect="blur"
                                            wrapperProps={{
                                                
                                                style: {transitionDelay: "0.5s"},
                                            }}
                                            /> 
              
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<section className='c-about-w c-about-pw-list'  >
    <div className='container'>
        <div className='c-about-hw'>
            <h2>Our Trusted <span>Customers</span></h2>
            
        </div>
        
        <div className='c-feature-w'>
            <div className='row'>
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box' style={{ minHeight:'266px' }}>
                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={105}
                                            src={About_Feature_1} 
                                            width={98}  
                                            effect="blur"
                                            wrapperProps={{
                                                
                                                style: {transitionDelay: "0.5s"},
                                            }}
                                            />  
                            <h2>Variety</h2>
                            
                            <p>We offer a wide range of photo editing and retouching servicesthat are sure to meet your needs that including color matching, ghost mannequins, background removal, clipping path,automotive photo editingand much more.</p>
                       
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box' style={{ minHeight:'266px' }}>
                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={105}
                                            src={About_Feature_2} 
                                            width={98}  
                                            effect="blur"
                                            wrapperProps={{
                                                
                                                style: {transitionDelay: "0.5s"},
                                            }}
                                            />   
                            <h2>Highest Quality</h2>
                            
                            <p>By hiring us, clients receive the highest quality solutions at the best rates in the industry, so you can save money while still getting high-quality results.</p>
                       
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box' style={{ minHeight:'266px' }}>
                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={105}
                                            src={About_Feature_3} 
                                            width={98}  
                                            effect="blur"
                                            wrapperProps={{
                                                
                                                style: {transitionDelay: "0.5s"},
                                            }}
                                            />  
                            <h2>Secure</h2>
                           
                            <p>Our process is secure, so you can rest assured that your photos are in good hands.</p>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section className='c-vision-w'>
  <div className='container'>
      <div className='row'>
          <div className='col-lg-6'>
              <div className='c-vision-img'>

                  <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={587}
                                            src={Vision} 
                                            width={857}  
                                            effect="blur"
                                            wrapperProps={{
                                                
                                                style: {transitionDelay: "0.5s"},
                                            }}
                                            />                            
                  
                  

              </div>  
          </div>  
          <div className='col-lg-6'>
              <div className='c-vision-con'>

              <ul class="nav nav-pills mb-4" id="pills-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active"  data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab"  aria-selected="true">Our Misson</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link"  data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab"  aria-selected="false">Our Vision</button>
                  </li>
                  
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="pills-home" role="tabpanel"  tabindex="0">
                      <h3> Know About Our <span>Mission</span></h3>
                      <p className='mb-4'>We know that we are carrying a big responsibility of shouldering the weight of expectations shown by our clients when they place their orders with us. Our mission is to keep growing our solutions so that we can continue to meet the needs of our clients. We are always looking for ways to improve our image editing solutions so that we can provide the best possible experience for our clients. We value feedback and suggestions from our clients so that we can continue to grow and improve.</p>
                      <Link to="/contact" className="c-btn-2"><span>Get a Free Trial</span></Link>  
                  </div>
                  <div class="tab-pane fade" id="pills-profile" role="tabpanel"  tabindex="0">
                      <h3> Know About Our <span>Vision</span></h3>
                      <p className='mb-4'>Visuals Clipping's vision is to provide value to enable our customer's long-term sustainable growth. We will achieve this by delivering high-quality photo retouching services that address the needs of our customers. We will always put our customers first and work to build lasting relationships with them. We will also continually invest in our people and our technology so that we can always be at the forefront of innovation.</p>
                      <Link to="/contact" className="c-btn-2"><span>Get a Free Trial</span></Link>
                  </div>
                </div>
                 
              </div>
          </div>    
      </div>     
  </div>
</section>
<section className='c-about-w c-inner-company'>
<div className='container'>
        <div className='c-about-hw'>
            <h2>Our Trusted <span>Customers</span> </h2>
         
            <LazyLoadImage 
            alt= {"visuals clipping"}
            height={82}
            src={Satisfied} 
            width={224}  
            effect="blur"
            wrapperProps={{
                
                style: {transitionDelay: "0.5s"},
            }}
            />
        </div>
        <Marquee>
        <LazyLoadImage 
            alt={"visuals clipping"}
            height={30}
            src={Company_1} 
            width={96}  
            effect="blur"
            wrapperProps={{
                
                style: {transitionDelay: "0.5s"},
            }}
            />
             <LazyLoadImage 
            alt={"visuals clipping"}
            height={30}
            src={Company_2} 
            width={96}  
            effect="blur"
            wrapperProps={{
                
                style: {transitionDelay: "0.5s"},
            }}
            />
             <LazyLoadImage 
            alt={"visuals clipping"}
            height={30}
            src={Company_3} 
            width={96}  
            effect="blur"
            wrapperProps={{
                
                style: {transitionDelay: "0.5s"},
            }}
            />
             <LazyLoadImage 
            alt={"visuals clipping"}
            height={30}
            src={Company_4} 
            width={96}  
            effect="blur"
            wrapperProps={{
                
                style: {transitionDelay: "0.5s"},
            }}
            />
             <LazyLoadImage 
            alt={"visuals clipping"}
            height={30}
            src={Company_5} 
            width={96}  
            effect="blur"
            wrapperProps={{
                
                style: {transitionDelay: "0.5s"},
            }}
            />
             <LazyLoadImage 
            alt={"visuals clipping"}
            height={30}
            src={Company_6} 
            width={96}  
            effect="blur"
            wrapperProps={{
                
                style: {transitionDelay: "0.5s"},
            }}
            />
             <LazyLoadImage 
            alt={"visuals clipping"}
            height={30}
            src={Company_7} 
            width={96}  
            effect="blur"
            wrapperProps={{
                
                style: {transitionDelay: "0.5s"},
            }}
            />
        </Marquee>
        
    </div>
 </section>      
    </>
  )
}

export default About
