import React from "react";
import { lazy, Suspense } from "react";

import Steper_1 from "../assets/img/steper-1.webp";
import Steper_2 from "../assets/img/steper-2.webp";
import Steper_3 from "../assets/img/steper-3.webp";
import {Helmet} from "react-helmet";
import Feature_New_1 from "../assets/img/feature-new-3.webp";
import Feature_New_2 from "../assets/img/feature-new-2.webp";
import Feature_New_3 from "../assets/img/feature-new-1.webp";

import Testi_1 from "../assets/img/testi-icon-1.webp";
import Faq_img from "../assets/img/faq-img.webp";
import Faq_img_png from "../assets/img/faq-img.png";
import { Link } from "react-router-dom/cjs/react-router-dom";
import pMinDelay from 'p-min-delay';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Banner_Section = lazy(() => import("./home/banner.js"));
const About_setion = lazy(() => pMinDelay(import('./home/About_setion.js'),5000));
const Feature_section = lazy(() => import("./home/Feature_section.js"));
const Service_sectin = lazy(() => import("./home/Home_Service_Section.js"));
const home = () => {
  return (
    <>
<Helmet>
<title>Enhance Your Images with Professional Photo Editing Services</title>
<meta name="description" content="Transform your visuals with top-notch professional photo retouching services from a leading clipping path and photo editing company. Elevate your content&amp;#x27;s visual appeal and quality today."/>
</Helmet>
     <div className="c-home-banner-w">
      <Suspense fallback={<p className="c-banner-lodder"> Loding... </p>}>
        <Banner_Section />
      </Suspense>
      </div> 
      <Suspense fallback={<p className="c-feature-lodder"> Loding... </p>}>
        <Feature_section />
      </Suspense>

      <Suspense fallback={<p className="c-service-lodder"> Loding... </p>}>
        <Service_sectin />
      </Suspense>

      <section className="c-step-w">
        <div className="container">
          <div className="c-step-in">
            <div className="c-step-hw">
              <h2>How Visuals Clipping Works?</h2>
              <p>3 hours express service is available for limited number of files and can be applicable to select jobs</p>
            </div>
            <div className="c-step-con">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="c-step-box">
                    
                    <LazyLoadImage 
                        alt={"visuals clipping"}
                        height={104}
                        src={Steper_3} 
                        width={102}  
                        effect="blur"
                        wrapperProps={{
                            
                            style: {transitionDelay: "1s"},
                        }}
                        />
                     
                    <h3>Upload Your Images</h3>
                    <p>Fast and user-friendly upload and download system.</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="c-step-box">
                  <LazyLoadImage 
                        alt={"visuals clipping"}
                        height={104}
                        src={Steper_2} 
                        width={102}  
                        effect="blur"
                        wrapperProps={{
                            
                            style: {transitionDelay: "1s"},
                        }}
                        />
                    <h3>Get Quote in 15 Minutes</h3>
                    <p>We will get back to you within 15 minutes with your personalized quote..</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="c-step-box">
                  <LazyLoadImage 
                        alt={"visuals clipping"}
                        height={104}
                        src={Steper_1} 
                        width={102}  
                        effect="blur"
                        wrapperProps={{
                            
                            style: {transitionDelay: "1s"},
                        }}
                        />
                    <h3>Download Finished Files</h3>
                    <p>Download the retouched images with in your given deliver time.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cn-feature-main">
        <div className="container">
          <div className="c-feature-w">
            <div className="c-heading-w">
              <h2>
                Trusted by <span>10,000+ companies</span> around the world
              </h2>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="c-feature-box">
                  
                  <LazyLoadImage 
            alt={"visuals clipping"}
            height={105}
            src={Feature_New_1} 
            width={98}  
            effect="blur"
            wrapperProps={{
                
                style: {transitionDelay: "1s"},
            }}
            />
                    
                  
                  <h2>99.8%</h2>
                  <h3>First Time Approval</h3>
                  <p>Remove the back and forth to reach the perfect result.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="c-feature-box">
                 
                  <LazyLoadImage 
            alt={"visuals clipping"}
            height={105}
            src={Feature_New_2} 
            width={98}  
            effect="blur"
            wrapperProps={{
                
                style: {transitionDelay: "1s"},
            }}
            />
                  
                  <h2>99.5%</h2>
                  <h3>On Time Delivery</h3>
                  <p>We hate being late as much as you do.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="c-feature-box">
                  
                  <LazyLoadImage 
            alt={"visuals clipping"}
            height={105}
            src={Feature_New_3} 
            width={98}  
            effect="blur"
            wrapperProps={{
                
                style: {transitionDelay: "1s"},
            }}
            />
                  
                  <h2>3 Hr</h2>
                  <h3>Turnaround Time</h3>
                  <p>With Flow, our customers receive images in as little as 1 hour.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="c-testimonial-w">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="c-testi-in">
                <div className="c-heading-w">
                  <h5>
                    <img src={Testi_1} alt="visuals clipping " /> Testimonial
                  </h5>
                  <h2>
                    What Our <span>Client</span> Says?
                  </h2>
                  <p>With our AI workflows, scaling to meet your photo processing needs is easy!</p>
                  <Link to="/contact" className="c-btn-1">
                    <span> Contact</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="c-testi-list">
                <div className="slider-container">
                  <Suspense fallback={<p> Loding </p>}>
                    <About_setion />
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="c-faq-w">
        <div className="container">
          <div className="c-heading-w">
            <h2>
              Frequently <span>Asked</span> Questions
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="c-faq-img">
              <LazyLoadImage 
						alt={"visuals clipping"}
						height={663}
						src={Faq_img} 
						width={672}  
						effect="blur"
						wrapperProps={{
							
							style: {transitionDelay: "1s"},
						}}
						/>
                {/* <picture>
                  <source srcset={Faq_img} type="image/webp" alt="visuals clipping " />
                  <source srcset={Faq_img_png} type="image/png" alt="visuals clipping " />
                  <img src={Faq_img_png} alt="yvisuals clipping" />
                </picture> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 mt-auto mb-auto">
              <div className="c-faq-list">
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        Do you have a minimum number of photos that you require for editing
                      </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>No! We accept any number of photos, so feel free to order as many images as you need!</p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Which type of customers do you work with?
                      </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>We work with a broad range of customers, including e-commercecompanies, photography studios, and marketing firms’ online jewellery sellers etc.</p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Can I get a free sample of your photo editing service?
                      </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>
                          Yes, we offer a free sample for first-time customers. <Link to="/contact">Contact</Link> us to send your free sample.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                        What are the different photo editing services offered by you?
                      </button>
                    </h2>
                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <ul>
                          <li> Clipping Path</li>
                          <li>Background Removal</li>
                          <li>eCommerce Image Editing</li>
                          <li>High-end Jewelry Retouching</li>
                          <li>Product Photo Editing</li>
                          <li>Ghost Mannequin Retouching</li>
                          <li>Color / Swatch Matching</li>
                          <li>Automobile Retouching</li>
                          <li>Furniture Photo Editing</li>
                          <li>Eyewear Photo Retouching</li>
                          <li>Footwear Photo Editing</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="heading5">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapseThree">
                        What are the different photo editing services offered by you?
                      </button>
                    </h2>
                    <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>You can reach us 24 hours a day, 7 days a week, and we serve customers from all parts of the world.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default home;
