import React from 'react';

import {
    Link
  } from "react-router-dom";
import Slider from "react-slick";
import { PiSealCheckDuotone } from "react-icons/pi";
import { MdOutlineDesignServices } from "react-icons/md";
import { LuTimerReset } from "react-icons/lu";
import { RiScissorsCutLine } from "react-icons/ri";
import { IoPricetagsOutline } from "react-icons/io5";
import { MdOutlineWorkHistory } from "react-icons/md";


import { LazyLoadImage } from 'react-lazy-load-image-component';
import { BeforeAfter } from 'react-simple-before-after'

import After_Slider_1 from '../assets/img/after-before/background-removal/bg-after-1.webp';
import After_Slider_2 from '../assets/img/after-before/background-removal/bg-after-2.webp';
import After_Slider_3 from '../assets/img/after-before/background-removal/bg-after-3.webp';
import After_Slider_4 from '../assets/img/after-before/background-removal/bg-after-4.webp';
import After_Slider_5 from '../assets/img/after-before/background-removal/bg-after-5.webp';
import After_Slider_6 from '../assets/img/after-before/background-removal/bg-after-6.webp';
import After_Slider_7 from '../assets/img/after-before/background-removal/bg-after-7.webp';
import After_Slider_8 from '../assets/img/after-before/background-removal/bg-after-8.webp';
import After_Slider_9 from '../assets/img/after-before/background-removal/bg-after-9.webp';
import After_Slider_10 from '../assets/img/after-before/background-removal/bg-after-10.webp';

import Before_Slider_1 from '../assets/img/after-before/background-removal/bg-before-1.webp';
import Before_Slider_2 from '../assets/img/after-before/background-removal/bg-before-2.webp';
import Before_Slider_3 from '../assets/img/after-before/background-removal/bg-before-3.webp';
import Before_Slider_4 from '../assets/img/after-before/background-removal/bg-before-4.webp';
import Before_Slider_5 from '../assets/img/after-before/background-removal/bg-before-5.webp';
import Before_Slider_6 from '../assets/img/after-before/background-removal/bg-before-6.webp';
import Before_Slider_7 from '../assets/img/after-before/background-removal/bg-before-7.webp';
import Before_Slider_8 from '../assets/img/after-before/background-removal/bg-before-8.webp';
import Before_Slider_9 from '../assets/img/after-before/background-removal/bg-before-9.webp';
import Before_Slider_10 from '../assets/img/after-before/background-removal/bg-before-10.webp';


import Service_accodian from '../assets/img/service-accodian.webp';


import {Helmet} from "react-helmet";

import Logo_png from '../assets/img/logo.png' 
import Logo_webp from '../assets/img/logo.webp' 
import { lazy, Suspense } from "react";
import Img_Icon from '../assets/img/img-icon.webp';
import Img_Icon_1 from '../assets/img/banner-icon-1.webp';
import Img_Icon_2 from '../assets/img/banner-icon-2.webp';
import Img_Icon_3 from '../assets/img/banner-icon-3.webp';
import Img_Icon_4 from '../assets/img/banner-icon-4.webp';
import Img_Banner_1 from '../assets/img/bg-background.webp';
import Img_Banner_1_png from '../assets/img/bg-background_png.png';
import Chat_img from '../assets/img/chat.webp';
import Chat_img_png from '../assets/img/chat.png';
import Testi_1 from '../assets/img/testi-icon-1.webp';
import Feature_New_1 from '../assets/img/feature-new-3.webp';
import Feature_New_2 from '../assets/img/feature-new-2.webp';
import Feature_New_3 from '../assets/img/feature-new-1.webp';

import Satisfied from '../assets/img/100-satisfied.webp';
import Marquee from "react-fast-marquee";

import Company_1 from '../assets/img/px-conversions/company-1.webp';
import Company_2 from '../assets/img/px-conversions/company-2.webp';
import Company_3 from '../assets/img/px-conversions/company-3.webp';
import Company_4 from '../assets/img/px-conversions/company-4.webp';
import Company_5 from '../assets/img/px-conversions/company-5.webp';
import Company_6 from '../assets/img/px-conversions/company-6.webp';
import Company_7 from '../assets/img/px-conversions/company-7.webp';
import { BsShieldFillCheck } from "react-icons/bs";

import Gallery_img_1 from '../assets/img/protfolio/background-removal/bg-gallery-1.webp';
import Gallery_img_png_1 from '../assets/img/protfolio/background-removal/bg-gallery-1.png';

import Gallery_img_2 from '../assets/img/protfolio/background-removal/bg-gallery-2.webp';
import Gallery_img_png_2 from '../assets/img/protfolio/background-removal/bg-gallery-2.png';


import Gallery_img_3 from '../assets/img/protfolio/background-removal/bg-gallery-3.webp';
import Gallery_img_png_3 from '../assets/img/protfolio/background-removal/bg-gallery-3.png';


import Gallery_img_4 from '../assets/img/protfolio/background-removal/bg-gallery-4.webp';
import Gallery_img_png_4 from '../assets/img/protfolio/background-removal/bg-gallery-4.png';

import pMinDelay from 'p-min-delay';
import Gallery_img_5 from '../assets/img/protfolio/background-removal/bg-gallery-5.webp';
import Gallery_img_png_5 from '../assets/img/protfolio/background-removal/bg-gallery-5.png';

const About_setion2 = lazy(() => pMinDelay(import('./home/About_setion.js'),5000));


 
const Background_Removal = () => {
  var settings_1 = {
    dots: true,
    arrows:false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
<>
<Helmet>
     <title>Masterful Photo Background Removal Services to Elevate Your Visuals</title>
     <meta name="description" content="Transform your images with our expert background removal services. Elevate your visuals today!" />
 </Helmet>
 
<section className="c-banner-w c-service-bnner-w">
	<div className="container">
		<div className="row align-items-center">
			<div className="col-lg-5 col-md-12">
				<div className="c-banner-con">
					<h5>
                    <img src={Img_Icon} alt="visuals clipping " />

                    Background Removal
					</h5>
                    
					<h1>Image  <span >Background <br></br> Removal </span>Services</h1>
					<ul>	
						<li>
							<div className="c-banner-feature">
								<span>	
                                <img src={Img_Icon_1} alt="visuals clipping " />	
								</span>
								100% Satisfaction
							</div>
						</li>	
						<li>
							<div className="c-banner-feature">
								<span>	
                                    <img src={Img_Icon_2} alt="visuals clipping " />
								</span>
								No Advanced Payment
							</div>
						</li>
						<li>
							<div className="c-banner-feature">
								<span>	
                                     <img src={Img_Icon_3} alt="visuals clipping " />
								</span>
								
								Unlimited Revision
							</div>
						</li>
						<li>
							<div className="c-banner-feature">
								<span>	
                                    <img src={Img_Icon_4} alt="visuals clipping " />	
								</span>
								
								10 Image Free Trail
							</div>
						</li>
					</ul>
                   
					<div className="c-banner-btn">
						<Link  to="/contact" className="c-btn-1">
							<span>	Get Quote Now</span>
						</Link>
						<button  data-bs-toggle="modal" data-bs-target=".c-model-popup-1" className="c-btn-2">
							<span>	Get a Free Trial</span>
						</button>
					</div>
				</div>
			</div>
			<div className="col-lg-7 col-md-12">
				<div className="c-banner-slider">
					<div className="c-banner-slider-list-w">
                    <Slider {...settings_1}>
                           <div className="c-banner-slider-list">


                           <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={751}
                                            src={Img_Banner_1} 
                                            width={726}  
                                            effect="blur"
                                            wrapperProps={{
                                                
                                                style: {transitionDelay: "1s"},
                                            }}
                                            /> 

                          
                            </div>
                            <div className="c-banner-slider-list">


                                <LazyLoadImage 
                                alt={"visuals clipping"}
                                                height={751}
                                                src={Img_Banner_1} 
                                                width={726}  
                                                effect="blur"
                                                wrapperProps={{
                                                    
                                                    style: {transitionDelay: "1s"},
                                                }}
                                                /> 


                                </div>
                            
                            
                        </Slider>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section className='c-about-w c-featurw-pw'>
    <div className='container'>
        <div className='c-about-hw'>
             <h2>Benefits to outsource Image Background Removal<br></br> Services to  <span>Visuals Clipping?</span> </h2>
             <p>You are not restricted to choices when you choose Visuals Clipping. Our clients receive personalized services as per their needs.</p>
           
        </div>
        <div className='cp-fea-list'>

<div className='cp-fea-list-box'>
    <div className='cp-fea-list-box-in'>
        <span>
            <LuTimerReset />
        </span>
        Time Saving
    </div>
</div>
<div className='cp-fea-list-box'>
    <div className='cp-fea-list-box-in'>
        <span>
            <RiScissorsCutLine />
        </span>
        Accuracy
    </div>
    <div className='cp-fea-list-box-in'>
        <span>
            <MdOutlineDesignServices />
        </span>
        100% <br></br>Customized <br></br> Service
    </div>
</div>

<div className='cp-fea-list-box c-fea-midel'>
    <div className='cp-fea-list-box-in'>
        <picture>
            <source srcset={Logo_webp} type="image/webp" alt="visuals clipping logo" />
            <source srcset={Logo_png} type="image/jpeg" alt="visuals clipping logo"/>
            <img src={Logo_png} alt="yazzoo homepage banner"  />
        </picture>
    </div>
</div>
<div className='cp-fea-list-box c-fea-right'>
    <div className='cp-fea-list-box-in'>
        <span>
        <MdOutlineWorkHistory />
        </span>
        Professional <br></br> Work
    </div>
    <div className='cp-fea-list-box-in'>
        <span>
            <IoPricetagsOutline />
        </span>
        Cost Effective
    </div>
</div>
<div className='cp-fea-list-box c-fea-right'>
    <div className='cp-fea-list-box-in'>
        <span>
            <BsShieldFillCheck />
        </span>
        Highest <br></br>standard
    </div>
</div>

</div>
    </div>
</section>  
<section className='c-service-accodian'>
    <div className='container'>
        <div className='row align-items-center'>
            <div className='col-md-6'>
                <div className='c-service-img'>
                <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={856}
                                            src={Service_accodian} 
                                            width={778}  
                                            effect="blur"
                                            wrapperProps={{
                                                
                                                style: {transitionDelay: "1s"},
                                            }}
                                            /> 
                       
                </div>
            </div>
            <div className='col-md-6'>
                    <div className='c-about-hw c-faq-group-in c-service-faq'>
                    <h2>Get <span>Professional</span> Image Background <br></br> Removal Services   </h2>
                    <p>Photo background removal using photoshop might seem easy, but it can be pretty challenging, especially if you have bulk images. Hire Visuals Clipping to handle your photo background removal project, and you'll receive the best services at the most competitive rates.</p>
                    <div className='row justify-content-center' >
                        <div className='col-md-12'>
                        <div class="accordion accordion-flush" id="accordionFlushExample-2">
                            <div class="accordion-item">
                                <h2 class="accordion-header" >
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-3" aria-expanded="false" >
                                A Wide Array of Background Removal Services
                                </button>
                                </h2>
                                <div id="flush-collapse-3" class="accordion-collapse collapse show"  data-bs-parent="#accordionFlushExample-2">
                                <div class="accordion-body">
                                At Visuals Clipping, we understand the importance of high-quality product images for your business. We're here to help you create the perfect product photos that will help boost your business. Our team of experts uses smart software, expert minds, a wide range of photo editing, and artificial intelligence tools to offer you a dependable photo background removal service and a wide range ofphoto retouching techniques for background removal that includes clipping paths, image masking, object removal or addition, and more.
                                </div>
                                </div>
                            </div>
                           

                           
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section className='c-service-tab-w'>
    <div className='container'>
          <div className='c-heading-w'>
                <h2>Quick and   <span>Effective</span> Background Removal Service  </h2>
                <p>At Visuals Clipping, we understand that the background is one of the most important aspects of any image.</p>
          </div>
          <div className='c-service-tab'>
                <ul class="nav nav-pills mb-4" id="pills-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active"  data-bs-toggle="pill" data-bs-target="#cp-tab-1" type="button" role="tab"  aria-selected="true">Simple
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link"  data-bs-toggle="pill" data-bs-target="#cp-tab-2" type="button" role="tab"  aria-selected="false">Medium</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link "  data-bs-toggle="pill" data-bs-target="#cp-tab-5" type="button" role="tab"  aria-selected="true">Masking</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link"  data-bs-toggle="pill" data-bs-target="#cp-tab-4" type="button" role="tab"  aria-selected="false">Intricate
                    </button>
                  </li>

                  <li class="nav-item" role="presentation">
                    <button class="nav-link "  data-bs-toggle="pill" data-bs-target="#cp-tab-3" type="button" role="tab"  aria-selected="true">Complex</button>
                  </li>
                  


                  
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="cp-tab-1" role="tabpanel"  tabindex="0">
                    
                      <div className='row'>
                            <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_1}
                                    afterImage={After_Slider_1}
                                    /* Other Props */
                                />
                            </div> 
                            <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_10}
                                    afterImage={After_Slider_10}
                                    
                                />
                            </div> 
                           
                            
                      </div>
                  </div>
                  <div class="tab-pane fade" id="cp-tab-2" role="tabpanel"  tabindex="0">
                      <div className='row'>
                            <div className='col-md-6 '>
                                <BeforeAfter
                                    beforeImage={Before_Slider_9}
                                    afterImage={After_Slider_9}
                                    /* Other Props */
                                />
                            </div> 
                            <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_8}
                                    afterImage={After_Slider_8}
                                    
                                />
                            </div> 
                       </div>     
                  </div>

                  <div class="tab-pane fade " id="cp-tab-5" role="tabpanel"  tabindex="0">
                      <div className='row'>
                            <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_4}
                                    afterImage={After_Slider_4}
                                    
                                />
                            </div> 
                            <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_5}
                                    afterImage={After_Slider_5}
                                    
                                />
                            </div> 
                            
                      </div>
                  </div>
                  <div class="tab-pane fade " id="cp-tab-4" role="tabpanel"  tabindex="0">
                      <div className='row'>
                           <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_6}
                                    afterImage={After_Slider_6}
                                    /* Other Props */
                                />
                            </div> 
                            <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_7}
                                    afterImage={After_Slider_7}
                                    /* Other Props */
                                />
                            </div> 
                            
                      </div>
                  </div>
                  <div class="tab-pane fade " id="cp-tab-3" role="tabpanel"  tabindex="0">
                      <div className='row'>
                           <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_3}
                                    afterImage={After_Slider_3}
                                    /* Other Props */
                                />
                            </div> 
                            
                            <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_2}
                                    afterImage={After_Slider_2}
                                    
                                />
                            </div> 
                            
                      </div>
                  </div>
                  
                </div>  
          </div>
    </div>
</section>


<section className='cn-feature-main cp-service-feature'>
    <div className='container'>
    <div className='c-feature-w'>
          <div className='c-heading-w'>
                <h2>What  <span>We Offer</span> </h2>
                <p>Are you in need of high-quality photo editing and retouching services?Look no further than us! </p>
          </div>
            <div className='row'>
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>

                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={105}
                                            src={Feature_New_1} 
                                            width={90}  
                                            effect="blur"
                                            wrapperProps={{
                                                
                                                style: {transitionDelay: "1s"},
                                            }}
                                            /> 

                          
                           
                            <h3>Simple <strong>Quick Background Removal Service</strong></h3>
                            <p>At Visuals Clipping, we understand that the background is one of the most important aspects of any image. That's why we offer a quick and effective background removal service that can help your images stand out. With our service, you can remove any image's background in just a few clicks. We don't just mean removing the background from an image - we can also change the background to any color or image you want. So, whether you're looking to remove a distracting background or simply want to change the look of an image, we can help.</p>
                       
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={105}
                                            src={Feature_New_1} 
                                            width={90}  
                                            effect="blur"
                                            wrapperProps={{
                                                
                                                style: {transitionDelay: "1s"},
                                            }}
                                            />    
                            
                            <h3>Medium <strong>remove unwanted background from photos?</strong></h3>
                            <p>At Visuals Clipping, we know the value of time and always provide quick solutions for bulk image editing. Whether you need us to remove the background from 100 photos or retouch 1000 product images, we will always meet your deadline. We understand that time is of the essence in the fast-paced world of online retail and e-commerce. That's why we offer a same-day turnaround time for most of our services. Moreover, we offer inexpensive background removal solutions for our clients.</p>
                       
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={105}
                                            src={Feature_New_1} 
                                            width={90}  
                                            effect="blur"
                                            wrapperProps={{
                                                
                                                style: {transitionDelay: "1s"},
                                            }}
                                            />    
                            
                            <h3>Complex <strong>Have complex images? No worries!</strong></h3>
                            <p>If you have been coming across issues like below-quality image background removal services, photo retouching, and slow editing pace that are becoming an obstacle to your business growth, you need not worry. We provide our background removal service for multiple types of products and can easily handle complex image editing needs with ease. We provide background removal solutions for Jewelry, fashion, eCommerce, automotive, apparels, furniture, footwear, eyewear, antique items and many more</p>
                       
                    </div>
                </div>

                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={105}
                                            src={Feature_New_1} 
                                            width={90}  
                                            effect="blur"
                                            wrapperProps={{
                                                
                                                style: {transitionDelay: "1s"},
                                            }}
                                            />     
                           
                            <h3>Intricate <strong>Intricate Images? We Can Handle It.</strong></h3>
                            <p>Are high-complexity images becoming a bottleneck in your production line? Or maybe your current image editing vendor is not giving you the quality you need? We help studios and brands handle complex background removal such as Fur, purse chain, fly away hairs, jewelry, home goods and furniture, complex and distracting background etc</p>
                       
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={105}
                                            src={Feature_New_1} 
                                            width={90}  
                                            effect="blur"
                                            wrapperProps={{
                                                
                                                style: {transitionDelay: "1s"},
                                            }}
                                            />     
                            
                            <h3>Masking <strong>Need assistance with photo masking? We do it without fail.</strong></h3>
                            <p>Image masking is the process of hiding or revealing part of an image. This can be done for a number of reasons, including to create a more aesthetically pleasing image or to focus attention on a certain area of the image. Masking can also be used to remove unwanted elements from an image, such as background noise or distracting objects. Visuals Clipping is a leading provider of image masking services. We offer a range of masking services that can be customized to suit the needs of any business. Whether you need to remove background noise from an image or create a custom mask for a product, we can help. Moreover, image masking is available for basic image masking to complex image masking like hair masking, channel masking, layer masking, fur masking, multiple masking etc.</p>
                       
                    </div>
                </div>
               
            </div>
        </div>
    </div>
</section>
<section className='c-testimonial-w'>
    <div className='container'>
        <div className='row align-items-center'>
            <div className='col-lg-6 col-md-12'>
              <div className='c-testi-in'>
                    <div className='c-heading-w'>
                        <h5><img src={Testi_1} alt="visuals clipping "/> Testimonial</h5>
                        <h2>What Our  <span>Client</span> Says?</h2>
                        <p>With our AI workflows, scaling to meet your photo processing needs is easy!</p>
                        <a  href="#" className="c-btn-1">
							<span>	Contact</span>
						</a>
                    </div>
              </div>  
            </div>
            <div className='col-lg-6 col-md-12'>
                <div className='c-testi-list'>
                    <div className="slider-container">
                    <Suspense fallback={ <p> Loding </p>}>
                        <About_setion2 />
                    </Suspense> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section className='c-price-table-w'>
    <div className='container'>
        <div className='c-heading-w'>
        <h2>Background Removal  <span> Price Chart </span> </h2>

        </div>
        <div class="table-responsive">
            <table class="table table-bordered">
                <tr>
                    <th>Work</th>
                    <th>Basic ($ 0.5/image)</th>
                    <th>Medium ($ 0.8/image)</th>
                    <th>Complex ($ 1.5 /image)</th>
                </tr>
                <tr>
                    <td> <strong>Product Type</strong> </td> 
                    <td><strong>Simple</strong></td>
                    <td><strong>Medium</strong></td>
                    <td><strong>Complex</strong></td>
                </tr>

                <tr>
                    <td> <strong>Dust / Reflection removal</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Drop Shadow / Reflection</strong> </td> 
                    <td></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Image Resizing</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Maximum Image Size</strong> </td> 
                    <td><strong>1500X1500</strong></td>
                    <td><strong>2000X2000</strong></td>
                    <td><strong>No Size Limit</strong></td>
                </tr>

                <tr>
                    <td> <strong>Image Enhancement</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Color Correction</strong> </td> 
                    <td><span></span></td>
                    <td><span></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>100% Customized Retouching</strong> </td> 
                    <td><span></span></td>
                    <td><span></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>


                <tr>
                    <td> <strong>Image padding</strong> </td> 
                    <td></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>


                <tr>
                    <td> <strong>Image Renaming</strong> </td> 
                    <td><span></span></td>
                    <td><span></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Multiple Format (JPG, PNG, Tiff, PSD, Other)</strong> </td> 
                    <td><span></span></td>
                    <td><span></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>


                
            </table>
        </div>
    </div>
</section>

<section className='c-portfolio-service'>
       <div className='container'>
            <div className='c-heading-w'>
                    <h2>Our Photo Editing  <span>Portfolio </span> </h2>
                    <p>At Visuals Clipping, we understand that the background is one of the most important aspects of any image.</p>
            </div>
            <div className='c-porfolio-in'>
                <div className='row'>
                        <div className='col-lg-6 col-md-6'>
                            <div className='c-porfolio-box'> 

                            <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={670}
                                            src={Gallery_img_1} 
                                            width={857}  
                                            effect="blur"
                                            wrapperProps={{
                                                
                                                style: {transitionDelay: "1s"},
                                            }}
                                            /> 
                                          
                            </div>         
                        </div>
                        <div className='col-lg-6 col-md-6'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6'>
                                    <div className='c-porfolio-box'> 
                                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={328}
                                            src={Gallery_img_2} 
                                            width={417}  
                                            effect="blur"
                                            wrapperProps={{
                                                
                                                style: {transitionDelay: "1s"},
                                            }}
                                            /> 
                                    </div> 
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                <div className='c-porfolio-box'> 
                                <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={328}
                                            src={Gallery_img_3} 
                                            width={417}  
                                            effect="blur"
                                            wrapperProps={{
                                                
                                                style: {transitionDelay: "1s"},
                                            }}
                                            /> 
                                    </div> 
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                <div className='c-porfolio-box'> 
                                <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={328}
                                            src={Gallery_img_4} 
                                            width={417}  
                                            effect="blur"
                                            wrapperProps={{
                                                
                                                style: {transitionDelay: "1s"},
                                            }}
                                            /> 
                                    </div> 
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                <div className='c-porfolio-box'> 
                                <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={328}
                                            src={Gallery_img_5} 
                                            width={417}  
                                            effect="blur"
                                            wrapperProps={{
                                                
                                                style: {transitionDelay: "1s"},
                                            }}
                                            /> 
                                    </div> 
                                </div>
                            </div>
                        </div>
                </div>
                <div className='text-center'>
                <Link to="/contact" className="c-btn-1"><span>Get a Free Trial</span> </Link>
                </div>
            </div>
            <div className='c-porfolio-con'>
                <div className='row'> 
                    <div className='col-lg-6 col-md-12'>
                        <div className='c-porfolio-con'> 
                            <div className='c-heading-w text-left'> 
                                <h2> Get Your Edits Done In <span>Record Time!</span></h2>
                                <p>At VisualsClipping, we know the value of time and always provide quick solutions for bulk image editing. Whether you need us to remove the background from 100 photos or retouch 1000 product images, we will always meet your deadline. We understand that time is of the essence in the fast-paced world of online retail and e-commerce. That's why we offer a same-day turnaround time for most of our services
                                </p>
                            </div>    
                         </div>       
                    </div>
                    <div className='col-lg-6 col-md-12'>
                        <div className='c-porfolio-con'> 
                            <div className='c-heading-w text-left'> 
                                <h2>Affordable ecommerce product Background  <span>removal services.</span></h2>
                                <p>There are many reasons to use a background removal service when editing photos for your business. It can help to create a more polished and professional look and can also be used to eliminate distractions from the background of a photo. Additionally, hiring an image background removal service can create more visually appealing photos that draw more focused attention to the photo's subject.
                                Visuals Clipping offers more than just photo retouching solutions. We work as a supplement to your business growth, and we aim to build long-term relationships with our clients. Currently, we process more than 100000 ecommerce images per month through our image editing. Call us now to learn how you can save thousands of dollars a month while getting your products online faster.</p>
                            </div>    
                         </div>       
                    </div>
                </div>
            </div>
        </div> 
</section>

<section className='c-about-w c-inner-company'>
<div className='container'>
        <div className='c-about-hw'>
            <h2>Our Trusted <span>Customers</span> </h2>
         
            <LazyLoadImage 
            alt={"visuals clipping"}
            height={82}
            src={Satisfied} 
            width={224}  
            effect="blur"
            wrapperProps={{
                
                style: {transitionDelay: "1s"},
            }}
            />
        </div>
        <Marquee>
        <LazyLoadImage 
            alt={"visuals clipping"}
            height={30}
            src={Company_1} 
            width={96}  
            effect="blur"
            wrapperProps={{
                
                style: {transitionDelay: "1s"},
            }}
            />
             <LazyLoadImage 
            alt={"visuals clipping"}
            height={30}
            src={Company_2} 
            width={96}  
            effect="blur"
            wrapperProps={{
                
                style: {transitionDelay: "1s"},
            }}
            />
             <LazyLoadImage 
            alt={"visuals clipping"}
            height={30}
            src={Company_3} 
            width={96}  
            effect="blur"
            wrapperProps={{
                
                style: {transitionDelay: "1s"},
            }}
            />
             <LazyLoadImage 
            alt={"visuals clipping"}
            height={30}
            src={Company_4} 
            width={96}  
            effect="blur"
            wrapperProps={{
                
                style: {transitionDelay: "1s"},
            }}
            />
             <LazyLoadImage 
            alt={"visuals clipping"}
            height={30}
            src={Company_5} 
            width={96}  
            effect="blur"
            wrapperProps={{
                
                style: {transitionDelay: "1s"},
            }}
            />
             <LazyLoadImage 
            alt={"visuals clipping"}
            height={30}
            src={Company_6} 
            width={96}  
            effect="blur"
            wrapperProps={{
                
                style: {transitionDelay: "1s"},
            }}
            />
             <LazyLoadImage 
            alt={"visuals clipping"}
            height={30}
            src={Company_7} 
            width={96}  
            effect="blur"
            wrapperProps={{
                
                style: {transitionDelay: "1s"},
            }}
            />
        </Marquee>
        
    </div>
 </section>
</>
  )
}

export default Background_Removal

