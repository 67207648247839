import React from 'react';
import {
    Link
  } from "react-router-dom";
import Slider from "react-slick";
import { PiSealCheckDuotone } from "react-icons/pi";
import { MdOutlineDesignServices } from "react-icons/md";
import { LuTimerReset } from "react-icons/lu";
import { RiScissorsCutLine } from "react-icons/ri";
import { IoPricetagsOutline } from "react-icons/io5";
import { MdOutlineWorkHistory } from "react-icons/md";

import { BeforeAfter } from 'react-simple-before-after'

import Service_accodian_png from '../assets/img/ghost-mannne-editing.png';
import Service_accodian from '../assets/img/ghost-mannne-editing.webp';
import Logo_png from '../assets/img/logo.png' 
import Logo_webp from '../assets/img/logo.webp' 
import { lazy,Suspense  } from 'react';
import Img_Icon from '../assets/img/img-icon.webp';
import Img_Icon_1 from '../assets/img/banner-icon-1.webp';
import Img_Icon_2 from '../assets/img/banner-icon-2.webp';
import Img_Icon_3 from '../assets/img/banner-icon-3.webp';
import Img_Icon_4 from '../assets/img/banner-icon-4.webp';
import Img_Banner_1 from '../assets/img/mannequin-photo-editing-banner.webp';
import Img_Banner_1_png from '../assets/img/mannequin-photo-editing-banner.png';
import Chat_img from '../assets/img/chat.webp';
import Chat_img_png from '../assets/img/chat.png';
import Testi_1 from '../assets/img/testi-icon-1.webp';
import Feature_New_1 from '../assets/img/feature-new-3.webp';
import Feature_New_2 from '../assets/img/feature-new-2.webp';
import Feature_New_3 from '../assets/img/feature-new-1.webp';

import Satisfied from '../assets/img/100-satisfied.webp';
import Marquee from "react-fast-marquee";



import After_Slider_1 from '../assets/img/after-before/Mannequin/after-1.webp';
import After_Slider_2 from '../assets/img/after-before/Mannequin/after-2.webp';
import After_Slider_3 from '../assets/img/after-before/Mannequin/after-3.webp';
import After_Slider_4 from '../assets/img/after-before/Mannequin/after-4.webp';
import After_Slider_5 from '../assets/img/after-before/Mannequin/after-5.webp';
import After_Slider_6 from '../assets/img/after-before/Mannequin/after-6.webp';
import After_Slider_7 from '../assets/img/after-before/Mannequin/after-7.webp';
import After_Slider_8 from '../assets/img/after-before/Mannequin/after-8.webp';
import After_Slider_9 from '../assets/img/after-before/Mannequin/after-9.webp';


import Before_Slider_1 from '../assets/img/after-before/Mannequin/before-1.webp';
import Before_Slider_2 from '../assets/img/after-before/Mannequin/before-2.webp';
import Before_Slider_3 from '../assets/img/after-before/Mannequin/before-3.webp';
import Before_Slider_4 from '../assets/img/after-before/Mannequin/before-4.webp';
import Before_Slider_5 from '../assets/img/after-before/Mannequin/before-5.webp';
import Before_Slider_6 from '../assets/img/after-before/Mannequin/before-6.webp';
import Before_Slider_7 from '../assets/img/after-before/Mannequin/before-7.webp';
import Before_Slider_8 from '../assets/img/after-before/Mannequin/before-8.webp';
import Before_Slider_9 from '../assets/img/after-before/Mannequin/before-9.webp';



import Company_1 from '../assets/img/px-conversions/company-1.webp';
import Company_2 from '../assets/img/px-conversions/company-2.webp';
import Company_3 from '../assets/img/px-conversions/company-3.webp';
import Company_4 from '../assets/img/px-conversions/company-4.webp';
import Company_5 from '../assets/img/px-conversions/company-5.webp';
import Company_6 from '../assets/img/px-conversions/company-6.webp';
import Company_7 from '../assets/img/px-conversions/company-7.webp';
import { BsShieldFillCheck } from "react-icons/bs";

import Gallery_img_1 from '../assets/img/protfolio/ghost-mannequin/gallery-1.webp';
import Gallery_img_png_1 from '../assets/img/protfolio/ghost-mannequin/gallery-1.png';

import Gallery_img_2 from '../assets/img/protfolio/ghost-mannequin/gallery-2.webp';
import Gallery_img_png_2 from '../assets/img/protfolio/ghost-mannequin/gallery-2.png';


import Gallery_img_3 from '../assets/img/protfolio/ghost-mannequin/gallery-3.webp';
import Gallery_img_png_3 from '../assets/img/protfolio/ghost-mannequin/gallery-3.png';


import Gallery_img_4 from '../assets/img/protfolio/ghost-mannequin/gallery-4.webp';
import Gallery_img_png_4 from '../assets/img/protfolio/ghost-mannequin/gallery-4.png';


import Gallery_img_5 from '../assets/img/protfolio/ghost-mannequin/gallery-5.webp';
import Gallery_img_png_5 from '../assets/img/protfolio/ghost-mannequin/gallery-5.png';
import {Helmet} from "react-helmet";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import pMinDelay from 'p-min-delay';

const About_setion2 = lazy(() => pMinDelay(import('./home/About_setion.js'),5000));

const Ghost_Mannequin = () => {
  var settings_1 = {
    dots: true,
    arrows:false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
 <Helmet>
      <title>Professional Invisible Ghost Mannequin Service | Ghost Mannequin Photo Editing</title>
      <meta name="description" content="For those who require high-quality image editing for their website, social networking accounts, or other professional requirements, Visuals Clipping provides ghost mannequin clipping services." />
 </Helmet>

<section className="c-banner-w">
	<div className="container">
		<div className="row align-items-center">
			<div className="col-lg-6 col-md-12">
				<div className="c-banner-con">
					<h5>
                    <img src={Img_Icon} alt="visuals clipping " />

                    Mannequin Retouching 
					</h5>
					<h1>Mannequin   <span >Retouching  </span> Services!</h1>
                    <ul>	
						<li>
							<div className="c-banner-feature">
								<span>	
                                <img src={Img_Icon_1} alt="visuals clipping " />	
								</span>
								100% Satisfaction
							</div>
						</li>	
						<li>
							<div className="c-banner-feature">
								<span>	
                                    <img src={Img_Icon_2} alt="visuals clipping " />
								</span>
								No Advanced Payment
							</div>
						</li>
						<li>
							<div className="c-banner-feature">
								<span>	
                                     <img src={Img_Icon_3} alt="visuals clipping " />
								</span>
								
								Unlimited Revision
							</div>
						</li>
						<li>
							<div className="c-banner-feature">
								<span>	
                                    <img src={Img_Icon_4} alt="visuals clipping " />	
								</span>
								
								10 Image Free Trail
							</div>
						</li>
					</ul>
					
					<div className="c-banner-btn">
						<Link  to="/contact" className="c-btn-1">
							<span>	Get Quote Now</span>
						</Link>
						<button  data-bs-toggle="modal" data-bs-target=".c-model-popup-1" className="c-btn-2">
							<span>	Get a Free Trial</span>
						</button>
					</div>
				</div>
			</div>
			<div className="col-lg-6 col-md-12">
				<div className="c-banner-slider">
					<div className="c-banner-slider-list-w">
                    <Slider {...settings_1}>
                           <div className="c-banner-slider-list">


                           <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={619}
                                            src={Img_Banner_1} 
                                            width={679}  
                                            effect="blur"
                                            wrapperProps={{
                                                
                                                style: {transitionDelay: "0.5s"},
                                            }}
                                            /> 

                          
                            </div>
                            <div className="c-banner-slider-list">


                                <LazyLoadImage 
                                alt={"visuals clipping"}
                                                height={619}
                                                src={Img_Banner_1} 
                                                width={679}  
                                                effect="blur"
                                                wrapperProps={{
                                                    
                                                    style: {transitionDelay: "0.5s"},
                                                }}
                                                /> 


                                </div>
                            
                            
                        </Slider>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section className='c-about-w c-featurw-pw'>
    <div className='container'>
        <div className='c-about-hw'>
             <h2>Benefits to outsource Mannequin Retouching <br></br> Services to  <span>Visuals Clipping?</span> </h2>
             <p>Why Outsource Mannequin Retouching to Visuals Clipping?</p>
           
        </div>
        <div className='cp-fea-list'>

<div className='cp-fea-list-box'>
    <div className='cp-fea-list-box-in'>
        <span>
            <LuTimerReset />
        </span>
        Time Saving
    </div>
</div>
<div className='cp-fea-list-box'>
    <div className='cp-fea-list-box-in'>
        <span>
            <RiScissorsCutLine />
        </span>
        Accuracy
    </div>
    <div className='cp-fea-list-box-in'>
        <span>
            <MdOutlineDesignServices />
        </span>
        100% <br></br>Customized <br></br> Service
    </div>
</div>

<div className='cp-fea-list-box c-fea-midel'>
    <div className='cp-fea-list-box-in'>
        <picture>
            <source srcset={Logo_webp} type="image/webp" alt="visuals clipping logo" />
            <source srcset={Logo_png} type="image/jpeg" alt="visuals clipping logo"/>
            <img src={Logo_png} alt="yazzoo homepage banner"  />
        </picture>     
    </div>
</div>
<div className='cp-fea-list-box c-fea-right'>
    <div className='cp-fea-list-box-in'>
        <span>
        <MdOutlineWorkHistory />
        </span>
        Professional <br></br> Work
    </div>
    <div className='cp-fea-list-box-in'>
        <span>
            <IoPricetagsOutline />
        </span>
        Cost Effective
    </div>
</div>
<div className='cp-fea-list-box c-fea-right'>
    <div className='cp-fea-list-box-in'>
        <span>
            <BsShieldFillCheck />
        </span>
        Highest <br></br>standard
    </div>
</div>

</div>
    </div>
</section>  
<section className='c-service-accodian'>
    <div className='container'>
        <div className='row align-items-center'>
            <div className='col-md-6'>
                <div className='c-service-img'>
                    <picture>
                        <source srcset={Service_accodian} type="image/webp" alt="visuals clipping " />
                        <source srcset={Service_accodian_png} type="image/png" alt="visuals clipping "/>
                        <img src={Service_accodian_png} alt="yvisuals clipping"  />
                    </picture>     
                </div>
            </div>
            <div className='col-md-6'>
                    <div className='c-about-hw c-faq-group-in c-service-faq'>
                    <h2>Ghost Mannequin RetouchingTo Make Your  <span>Products Saleable.</span>    </h2>
                    <p>
                    To attract customers in today's digital world, eCommerce businesses must make their clothing look as appealing and realistic. We provide professional and affordable ghost mannequin editing to enhance your apparel photography and increase sales.For <a href="https://www.visualsclipping.com/ecommerce-image-editing-service">eCommerce businesses, mannequin photo editing</a> is essential because they make clothing items look more appealing and realistic. It will look more attractive and natural to customer, which will encourage them to buy.
                    </p>
                    
                </div>
            </div>
        </div>
    </div>
</section>

<section className='c-service-tab-w'>
    <div className='container'>
          <div className='c-heading-w'>
                <h2>Quick and   <span>Effective</span>Mannequin Retouching Service  </h2>
                <p>At Visuals Clipping, we understand that the background is one of the most important aspects of any image.</p>
          </div>
          <div className='c-service-tab'>
          <ul class="nav nav-pills mb-4" id="pills-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active"  data-bs-toggle="pill" data-bs-target="#cp-tab-1" type="button" role="tab"  aria-selected="true">Background Remove
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link"  data-bs-toggle="pill" data-bs-target="#cp-tab-2" type="button" role="tab"  aria-selected="false">Wrinkle Remove</button>
                  </li>

                  <li class="nav-item" role="presentation">
                    <button class="nav-link "  data-bs-toggle="pill" data-bs-target="#cp-tab-3" type="button" role="tab"  aria-selected="true">Symmetry</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link"  data-bs-toggle="pill" data-bs-target="#cp-tab-4" type="button" role="tab"  aria-selected="false">Model Superimpose
                    </button>
                  </li>


                 
                  
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="cp-tab-1" role="tabpanel"  tabindex="0">
                      <div className='row'>
                           <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_8}
                                    afterImage={After_Slider_8}
                                  
                                />
                            </div> 
                            <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_2}
                                    afterImage={After_Slider_2}
                                    
                                />
                            </div> 
                      </div>
                  </div>
                  <div class="tab-pane fade" id="cp-tab-2" role="tabpanel"  tabindex="0">
                      <div className='row'>
                           <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_5}
                                    afterImage={After_Slider_5}
                                    /* Other Props */
                                />
                            </div> 
                            <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_9}
                                    afterImage={After_Slider_9}
                                    
                                />
                            </div> 
                      </div>
                  </div>

                  <div class="tab-pane fade " id="cp-tab-3" role="tabpanel"  tabindex="0">
                      <div className='row'>
                           <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_4}
                                    afterImage={After_Slider_4}
                                    /* Other Props */
                                />
                            </div> 
                            <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_6}
                                    afterImage={After_Slider_6}
                                    
                                />
                            </div> 
                      </div>
                  </div>
                  <div class="tab-pane fade " id="cp-tab-4" role="tabpanel"  tabindex="0">
                      <div className='row'>
                           <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_1}
                                    afterImage={After_Slider_1}
                                    /* Other Props */
                                />
                            </div> 
                            <div className='col-md-6'>
                                <BeforeAfter
                                    beforeImage={Before_Slider_3}
                                    afterImage={After_Slider_3}
                                    
                                />
                            </div> 
                      </div>
                  </div>
                  
                  
                </div>  
          </div>
    </div>
</section>


<section className='cn-feature-main cp-service-feature'>
    <div className='container'>
    <div className='c-feature-w'>
          <div className='c-heading-w'>
                <h2>What  <span>We Offer</span> </h2>
                <p>Are you in need of high-quality photo editing and retouching services?Look no further than us! </p>
          </div>
            <div className='row'>
                <div className='col-lg-3 col-md-6'>
                    <div className='c-feature-box'>
                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={105}
                                            src={Feature_New_1} 
                                            width={90}  
                                            effect="blur"
                                            wrapperProps={{
                                                
                                                style: {transitionDelay: "0.5s"},
                                            }}
                                            />     
                           
                            <h3>Apparel background removal</h3>
                            <p>With background removal services, you can highlight your clothes in better condition in their images. Our flawless apparel background removal service will help you publish top-quality photos on your website or other online platforms, increasing customer engagement and sales. Choose us to help your online sales grow.</p>
                       
                    </div>
                </div>
                <div className='col-lg-3 col-md-6'>
                    <div className='c-feature-box'>
                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={105}
                                            src={Feature_New_1} 
                                            width={90}  
                                            effect="blur"
                                            wrapperProps={{
                                                
                                                style: {transitionDelay: "0.5s"},
                                            }}
                                            />      
                            
                            <h3>Apparel wrinkles removal</h3>
                            <p>The appearance of Wrinkles in clothing photos can be a major turn-off, making them look unprofessional. However, Visuals Clipping’s apparel wrinkle removal service will help you present your products in the best condition and increase the chances of sales. Hire our team of skilled professionals equipped with top-notch Photoshop techniques.</p>
                       
                    </div>
                </div>
                <div className='col-lg-3 col-md-6'>
                    <div className='c-feature-box'>
                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={105}
                                            src={Feature_New_1} 
                                            width={90}  
                                            effect="blur"
                                            wrapperProps={{
                                                
                                                style: {transitionDelay: "0.5s"},
                                            }}
                                            />      
                            
                            <h3>Superimpose model</h3>
                            <p>With our professional services for adding clothing to models in product photography editing, you can seamlessly merge your clothing design photos with models' photos. This service will help you showcase your designs on a model, making your product more attractive and increasing customer engagement. Contact now for discounted quotes.</p>
                       
                    </div>
                </div>

                <div className='col-lg-3 col-md-6'>
                    <div className='c-feature-box'>
                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={105}
                                            src={Feature_New_1} 
                                            width={90}  
                                            effect="blur"
                                            wrapperProps={{
                                                
                                                style: {transitionDelay: "0.5s"},
                                            }}
                                            />     
                           
                            <h3>Ghost mannequin neck joint</h3>
                            <p>Eliminate the ghost mannequin look from your apparel photos, which can be distracting and unappealing to customers, using our ghost mannequin neck joint. You will achieve a seamless look for your products and increase the chances of customer engagement. Hiring our team will ensure high-quality editing for your apparel photos.</p>
                       
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</section>
<section className='c-testimonial-w'>
    <div className='container'>
        <div className='row align-items-center'>
            <div className='col-lg-6 col-md-12'>
              <div className='c-testi-in'>
                    <div className='c-heading-w'>
                        <h5><img src={Testi_1} alt="visuals clipping "/> Testimonial</h5>
                        <h2>What Our  <span>Client</span> Says?</h2>
                        <p>With our AI workflows, scaling to meet your photo processing needs is easy!</p>
                        <a  href="#" className="c-btn-1">
							<span>	Contact</span>
						</a>
                    </div>
              </div>  
            </div>
            <div className='col-lg-6 col-md-12'>
                <div className='c-testi-list'>
                    <div className="slider-container">
                    <Suspense fallback={ <p> Loding </p>}>
                        <About_setion2 />
                    </Suspense> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section className='c-price-table-w'>
    <div className='container'>
        <div className='c-heading-w'>
        <h2>Ghost Mannequin Retouching  <span> Price Chart </span> </h2>

        </div>
        <div class="table-responsive">
            <table class="table table-bordered">
                <tr>
                    <th>Work</th>
                    <th>Simple ($ 0.8/image)</th>
                    <th>Medium ($ 1/image)</th>
                    <th>Advance ($ 2 /image)</th>
                </tr>
                <tr>
                    <td> <strong>Background Removal</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Color Enhancement</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Swatch Matching</strong> </td> 
                    <td><span></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>SKU's color matching</strong> </td> 
                    <td></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Maximum Image Size</strong> </td> 
                    <td>1500x1500</td>
                    <td>2000X2000</td>
                    <td>No Size Limit</td>
                </tr>

                <tr>
                    <td> <strong>Image padding</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Image Renaming</strong> </td> 
                    <td><span></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Mannequin Symmetry</strong> </td> 
                    <td></td>
                    <td></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>


                <tr>
                    <td> <strong>Wrinkle Removal</strong> </td> 
                    <td></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>


                <tr>
                    <td> <strong>Front & Back matching</strong> </td> 
                    <td></td>
                    <td></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Mannequin Replace with Model</strong> </td> 
                    <td></td>
                    <td></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>


                <tr>
                    <td> <strong>Multiple Format (JPG, PNG, Tiff, PSD, Other)</strong> </td> 
                    <td></td>
                    <td></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

               
            </table>
        </div>
    </div>
</section>

<section className='c-portfolio-service'>
       <div className='container'>
            <div className='c-heading-w'>
                    <h2>Our Photo Editing  <span>Portfolio </span> </h2>
                    <p>At Visuals Clipping, we understand that the background is one of the most important aspects of any image.</p>
            </div>
            <div className='c-porfolio-in'>
                <div className='row'>
                        <div className='col-lg-6 col-md-6'>
                            <div className='c-porfolio-box'> 

                            <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={670}
                                            src={Gallery_img_1} 
                                            width={857}  
                                            effect="blur"
                                            wrapperProps={{
                                                
                                                style: {transitionDelay: "0.5s"},
                                            }}
                                            /> 
                                          
                            </div>         
                        </div>
                        <div className='col-lg-6 col-md-6'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6'>
                                    <div className='c-porfolio-box'> 
                                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={328}
                                            src={Gallery_img_2} 
                                            width={417}  
                                            effect="blur"
                                            wrapperProps={{
                                                
                                                style: {transitionDelay: "0.5s"},
                                            }}
                                            /> 
                                    </div> 
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                <div className='c-porfolio-box'> 
                                <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={328}
                                            src={Gallery_img_3} 
                                            width={417}  
                                            effect="blur"
                                            wrapperProps={{
                                                
                                                style: {transitionDelay: "0.5s"},
                                            }}
                                            /> 
                                    </div> 
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                <div className='c-porfolio-box'> 
                                <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={328}
                                            src={Gallery_img_4} 
                                            width={417}  
                                            effect="blur"
                                            wrapperProps={{
                                                
                                                style: {transitionDelay: "0.5s"},
                                            }}
                                            /> 
                                    </div> 
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                <div className='c-porfolio-box'> 
                                <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={328}
                                            src={Gallery_img_5} 
                                            width={417}  
                                            effect="blur"
                                            wrapperProps={{
                                                
                                                style: {transitionDelay: "0.5s"},
                                            }}
                                            /> 
                                    </div> 
                                </div>
                            </div>
                        </div>
                </div>
                <div className='text-center'>
                <Link to="/contact" className="c-btn-1"><span>Get a Free Trial</span> </Link>
                </div>
            </div>
            <div className='c-porfolio-con'>
                <div className='row'> 
                    <div className='col-lg-6 col-md-12'>
                        <div className='c-porfolio-con'> 
                            <div className='c-heading-w text-left'> 
                                <h2> Make ordinary apparel images extraordinary with fast and accurate color <span>correction services.</span></h2>
                                <p>There is no doubt that high-quality apparel images are crucial for success in today's fast-paced and competitive e-commerce world. To succeed in the fiercely competitive apparel market, you must ensure that your product images accurately depict your offerings. Customers now demand a high level of product visualization and want to see exactly what they are purchasing, thanks to the growth of e-commerce. This is why apparel color correction is crucial. Furthermore, using the Pen tool on complex images by hiring <a href="https://www.visualsclipping.com/"> professional photo retouching services</a> for photo clipping tasks doesn’t always guarantee high success and quality because it is a difficult art to master.You can make sure that the colors of your products are accurately portrayed online by making <a href="https://www.visualsclipping.com/photo-color-correction-service">color corrections to your product images</a>. As a result, you receive fewer returns, and your customers are happier. Additionally, they are more likely to purchase from you again and spread the word about your business. And Visuals Clipping is the name you can rely on for clothing color correction! We recognize how crucial it is to present customers with accurate product images. We, therefore, provide excellent apparel color correction services to assist you in reducing returns and raising customer satisfaction.Our team of photo retouchers is highly experienced and skilled, and they use state-of-the-art tools and methods to give you quick but dependable services. Moreover, it is not all. Not only are our retouching solutions of the highest caliber, but they are also affordable and flawless. Large orders are no problem for us, and we can finish them quickly and effectively. Last but most importantly, our team is always available (24*7) to give you dependable customer service and ensure you get the results you need.</p>
                            </div>    
                         </div>       
                    </div>
                    <div className='col-lg-6 col-md-12'>
                        <div className='c-porfolio-con'> 
                            <div className='c-heading-w text-left'> 
                                <h2>  Ghost Mannequin's Neck  <span>Joint Service</span></h2>
                                <p>Model or mannequin ghosting and neck jointing services involve taking multiple photographs of a product on a model or mannequin, then removing the model or mannequin in post-production.. This service is often used by businesses that sell clothing, as it allows them to display their products in a more realistic way. Our neck joint Professional Invisible ghost mannequin service will provide businesses with a mannequin that has become invisible or hollow, and we have the ability to customize the mannequin effect to match the specific needs.</p>
                                    <p>If you're looking for a high-quality neck joint ghost mannequin service, look no further than Visuals Clipping . We provide a flawless service that will leave your mannequins looking perfectly natural. We use the latest technology and techniques to ensure that your mannequins look their best. Our neck joint ghost mannequin service is perfect for businesses of all sizes and we work with you to ensure that your mannequins are living up to your brand standards. We provide a hassle-free service that will save you time and money.</p>    
                                    <p>So if you're looking for a top-notch neck joint ghost mannequin service, contact us today.</p>
                            </div>    
                         </div>       
                    </div>
                </div>
            </div>
        </div> 
</section>



<section className='c-about-w c-inner-company'>
<div className='container'>
        <div className='c-about-hw'>
            <h2>Our Trusted <span>Customers</span> </h2>
         
            <LazyLoadImage 
            alt={"visuals clipping"}
            height={82}
            src={Satisfied} 
            width={224}  
            effect="blur"
            wrapperProps={{
                
                style: {transitionDelay: "0.5s"},
            }}
            />
        </div>
        <Marquee>
        <LazyLoadImage 
            alt={"visuals clipping"}
            height={30}
            src={Company_1} 
            width={96}  
            effect="blur"
            wrapperProps={{
                
                style: {transitionDelay: "0.5s"},
            }}
            />
             <LazyLoadImage 
            alt={"visuals clipping"}
            height={30}
            src={Company_2} 
            width={96}  
            effect="blur"
            wrapperProps={{
                
                style: {transitionDelay: "0.5s"},
            }}
            />
             <LazyLoadImage 
            alt={"visuals clipping"}
            height={30}
            src={Company_3} 
            width={96}  
            effect="blur"
            wrapperProps={{
                
                style: {transitionDelay: "0.5s"},
            }}
            />
             <LazyLoadImage 
            alt={"visuals clipping"}
            height={30}
            src={Company_4} 
            width={96}  
            effect="blur"
            wrapperProps={{
                
                style: {transitionDelay: "0.5s"},
            }}
            />
             <LazyLoadImage 
            alt={"visuals clipping"}
            height={30}
            src={Company_5} 
            width={96}  
            effect="blur"
            wrapperProps={{
                
                style: {transitionDelay: "0.5s"},
            }}
            />
             <LazyLoadImage 
            alt={"visuals clipping"}
            height={30}
            src={Company_6} 
            width={96}  
            effect="blur"
            wrapperProps={{
                
                style: {transitionDelay: "0.5s"},
            }}
            />
             <LazyLoadImage 
            alt={"visuals clipping"}
            height={30}
            src={Company_7} 
            width={96}  
            effect="blur"
            wrapperProps={{
                
                style: {transitionDelay: "0.5s"},
            }}
            />
        </Marquee>
        
    </div>
 </section>
</>
  )
}

export default Ghost_Mannequin


